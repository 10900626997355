import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const CaptainSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const { t, i18n } = useTranslation();

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: "25%",
    afterChange: (current) => setCurrentSlide(current),
    // autoplay: true,
    // autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1,
          centerMode: false, // Disable center mode on mobile
          centerPadding: "0%",
        },
      },
    ],
  };

  const slides = [
    {
      videoSrc: `${process.env.REACT_APP_APIENDPOINTVIDEO}video_1_new.mp4`,
      videoType: "video/mp4",
      title: t("Padel Venues"),
      description: t("Search, Book, Play"),
    },
    {
      videoSrc: `${process.env.REACT_APP_APIENDPOINTVIDEO}video_2.mov`,
      videoType: "video/mp4",
      title: t("Padel Venues"),
      description: t("Search, Book, Play"),
    },
    {
      videoSrc: `${process.env.REACT_APP_APIENDPOINTVIDEO}video_3_new.mp4`,
      videoType: "video/mp4",
      title: t("Padel Venues"),
      description: t("Search, Book, Play"),
    },
  ];

  return (
    <div className="captain_slider slick-initialized slick-slider">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`captainslider_box slick-slide ${
              index === currentSlide
                ? "slick-current slick-active slick-center"
                : "slick-cloned"
            }`}
          >
            <video width="100%" autoPlay muted playsInline>
              <source src={slide.videoSrc} type={slide.videoType} />
            </video>
            <div className="captainslider_content">
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CaptainSlider;
