import React from "react";
import { useSubscribeMutation } from "../services/Post";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Loader from "../customloader/Spinloaders";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Footer({ selectedLanguage }) {
  const [subscribe, { isLoading: loading }] = useSubscribeMutation();
  console.log("selectedLanguage", selectedLanguage);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm();

  const handleSubscribe = async (info) => {
    try {
      const res = await subscribe({
        email: info?.email,
        language: selectedLanguage === "gr" ? "Greek" : "English",
      });

      if (!res?.data?.error) {
        toast.success(
          res.data.message || t("You have successfully subscribed!")
        );
        reset();
      } else {
        toast.error(
          res.data.message || t("Subscription failed. Please try again.")
        );
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again later."));
    }
  };

  return (
    <>
      <footer className="footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mb-lg-0 mb-md-5 mb-4">
              <div className="footer_about">
                <a className="logo" href="javascript:void(0)">
                  <img src="assets/img/logo_footer.png" alt />
                </a>
                {/* <a
                  className="footer_btn"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Coming Soon"
                  href="javascript:void(0)"
                >
                  {t("Download now")}
                </a> */}
              </div>
            </div>
            <div className="col-lg-6 mb-lg-0 mb-md-5 mb-4">
              <div className="row">
                {/* <div class="col-md-4 mb-md-0 mb-4">
                  <div class="footer_menus">
                     <h2>Company</h2>
                     <ul>
                        <li>
                           <a href="javascript:void(0)">Features</a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">Partner Now</a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">Who We Are</a>
                        </li>
                        <li>
                           <a href="javascript:void(0)">What We Do</a>
                        </li>
                     </ul>
                  </div>
               </div> */}
                <div className="col-md-6 mb-md-0 mb-4">
                  <div className="footer_menus">
                    <h2>{t("Use Athlopolis")}</h2>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">{t("Android")}</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">{t("iPhone")}</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">{t("Mac/PC")}</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">{t("WebApp")}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="footer_menus">
                    <h2>{t("Need help?")}</h2>
                    <ul>
                      <li>
                        <Link
                          // data-bs-toggle="tooltip"
                          // data-bs-placement="top"
                          title="info@athlopolis.com"
                          to="/contact-us"
                        >
                          {t("Contact Us")}
                        </Link>
                      </li>
                      <li>
                        <a href="javascript:void(0)">{t("FAQs")}</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">{t("Tips")}</a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          {t("Security Advisories")}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer_newsletter">
                <h2>{t("Newsletter")}</h2>
                <form
                  className="newsletter_box"
                  action=""
                  onSubmit={handleSubmit(handleSubscribe)}
                >
                  <div className="form-group position-relative">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={classNames(
                        "form-control border border-secondary ",
                        {
                          "is-invalid": errors?.email,
                        }
                      )}
                      placeholder={t("Enter your email")}
                      {...register("email", {
                        required: t("Email is Required*"),
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t("Invalid email address"),
                        },
                      })}
                    />
                    {/* <button type="submit" disabled={loading}>
                      Subscribe
                    </button> */}
                    <button
                      type={loading ? "button" : "submit"}
                      disabled={loading}
                      style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    >
                      {loading ? (
                        <Spinner style={{ fontSize: "2rem" }} />
                      ) : (
                        t("Subscribe")
                      )}
                    </button>
                  </div>
                  {errors?.email && (
                    <small className="errorText mx-1 fw-bold text-danger mt-2">
                      {errors?.email?.message}
                    </small>
                  )}
                </form>
                <div className="appdownload">
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://apps.apple.com/us/app/athlopolis/id6618139109"
                    target="_blank"
                  >
                    <img src="assets/img/appstore.svg" alt />
                  </Link>
                  <Link
                    // data-bs-toggle="tooltip"
                    // data-bs-placement="top"
                    title="Download"
                    to="https://play.google.com/store/apps/details?id=com.athlopolis"
                    target="_blank"
                  >
                    <img src="assets/img/google-play.svg" alt />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <section className="copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-lg-0 mb-md-4 mb-4 d-flex align-items-center justify-content-lg-start justify-content-md-center justify-content-center">
              <div className="copyright_data me-md-4">
                {t("2024 English © Athlopolis")}
              </div>
              <div className="copyright_links mx-1">
                <Link to="/terms-condition">{t("Terms and Conditions")}</Link>
                {/* &amp;
                <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                &amp;
                <Link to="/about-us">{t("About Us")}</Link> */}
              </div>
              <div className="copyright_links mx-1">
                <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
              </div>
              <div className="copyright_links mx-1">
                <Link to="/about-us">{t("About Us")}</Link>
              </div>
            </div>
            <div className="col-lg-6 mb-lg-0 mb-md-4 mb-4">
              <div className="social_footer">
                <Link
                  // className="social_ico"
                  to="https://www.instagram.com/athlopolis_app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/insta.svg" alt="instagram" />
                </Link>
                <Link
                  // className="social_ico"
                  to="https://www.facebook.com/profile.php?id=61555006059082"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/facebook.svg" alt="facebook" />
                </Link>
                <Link
                  // className="social_ico"
                  to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/In.svg" alt="linkedin" />
                </Link>
                <Link
                  // className="social_ico"
                  to={`https://x.com/home?lang=${selectedLanguage}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/X.svg" alt="tweet" />
                </Link>
                {/* <a href="javascript:void(0)">
                  <img src="assets/img/Insta.png" alt />
                </a>
                <a href="javascript:void(0)">
                  <img src="assets/img/Facebook.png" alt />
                </a>
                <a href="javascript:void(0)">
                  <img src="assets/img/In.png" alt />
                </a>
                <a href="javascript:void(0)">
                  <img src="assets/img/TikTok.png" alt />
                </a>
                <a href="javascript:void(0)">
                  <img src="assets/img/YT.png" alt />
                </a>
                <a href="javascript:void(0)">
                  <img src="assets/img/X.png" alt />
                </a> */}
              </div>
            </div>
            {/* <div class="col-lg-4 col-md-6">
            <div class="copyright_select">
               <form class="row" action="#">
                  <div class="form-group col-md-6 mb-md-0 mb-3">
                     <select class="form-select" aria-label="Default select example">
                        <option selected >English</option>
                        <option value="1">Arbic</option> 
                      </select>
                  </div>
                  <div class="form-group col-md-6">
                     <select class="form-select" aria-label="Default select example">
                        <option selected>$ USD</option>
                        <option value="1">SAR</option> 
                      </select>
                  </div>
               </form>
            </div>
         </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
