import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const PostApi = createApi({
  reducerPath: "PostApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: "https://patenta-sa.com:2053/",
    baseUrl: `${process.env.REACT_APP_APIENDPOINT}`,
    // headers: {
    //   "x-auth-token-user": localStorage.getItem("patentatokenwebsite"),
    // },
  }),
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => {
        return {
          url: "user/user-login",
          method: "post",
          body,
        };
      },
    }),
    subscribe: builder.mutation({
      query: (body) => {
        // const { selector, ...data } = body;

        return {
          url: "api/user/subscribe",
          method: "post",
          body: body,
        };
      },
    }),
    contactUs: builder.mutation({
      query: (body) => {
        // const { selector, ...data } = body;

        return {
          url: "api/user/contactUs",
          method: "post",
          body: body,
        };
      },
    }),
    getContent: builder.mutation({
      query: (body) => {
        // const { selector, ...data } = body;

        return {
          url: "api/user/getContent",
          method: "PATCH",
          body: body,
        };
      },
    }),
  }),
});

export const {
  useUserLoginMutation,
  useSubscribeMutation,
  useGetContentMutation,
  useContactUsMutation,
} = PostApi;
