import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

// Define the slides data

const CommunitySlider = () => {
  const { t } = useTranslation();
  const slidesData = [
    {
      id: 1,
      span: t("Athlopolis has"),
      title: t("Partnered with the best \n padel Venues in Greece"),
      description: t("JOIN THE ATHLOPOLIS COMMUNITY"),
    },
    {
      id: 2,
      span: t("Athlopolis has"),
      title: t("Partnered with the best \n padel Venues in Greece"),
      description: t("JOIN THE ATHLOPOLIS COMMUNITY"),
    },
    {
      id: 3,
      span: t("Athlopolis has"),
      title: t("Partnered with the best \n padel Venues in Greece"),
      description: t("JOIN THE ATHLOPOLIS COMMUNITY"),
    },
  ];
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <button className="slick-next slick-arrow" aria-label="Next">
        Next
      </button>
    ),
    prevArrow: (
      <button className="slick-prev slick-arrow" aria-label="Previous">
        Previous
      </button>
    ),
  };

  return (
    <div className="cumminity_slider">
      <Slider {...settings}>
        {slidesData.map((slide) => (
          <div key={slide?.id}>
            <div className="cumminity_box">
              <div>
                <span>{slide.span}</span>
                <h2 style={{ width: "60%" }}>{slide.title}</h2>
                <p>{slide.description}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CommunitySlider;
