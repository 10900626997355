import React, { useEffect, useState } from "react";
import Footer from "../commoncomponents/Footer";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import classNames from "classnames";
import { useContactUsMutation } from "../services/Post";
import { Spinner } from "react-bootstrap";

function ContactUs() {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [contact, { isLoading: loading }] = useContactUsMutation();

  console.log("selectedLanguage", selectedLanguage);

  const { t, i18n } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    clearErrors,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const handlePhoneChange = (value, country) => {
    const phoneWithoutDialCode = value.replace(`${country.dialCode}`, "");
    console.log("Phone Number:", phoneWithoutDialCode);
    console.log("Country Code:", country.dialCode);
    setPhone(phoneWithoutDialCode);
    setCountryCode(country.dialCode);
    trigger("contactNumber");
    setValue("contactNumber", phoneWithoutDialCode);
    if (phoneWithoutDialCode) {
      clearErrors("contactNumber");
    }
  };

  const onSubmit = async (info) => {
    const alldata = {
      first_name: info?.firstName,
      last_name: info?.lastName,
      phone_number: phone,
      country_code: `+${countryCode}`,
      gender: info?.gender,
      email: info?.email,
      description: info?.description,
      language: selectedLanguage === "gr" ? "Greek" : "English",
    };

    try {
      const res = await contact(alldata);
      if (!res?.data?.error) {
        toast.success(res?.data?.message);
        reset();
        setPhone("");
        setCountryCode("");
        setValue("contactNumber", "");
        window?.location?.reload();
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      console.error("Error submitting contact form:", error);
    }
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "en";
    setSelectedLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    toast.success(
      `Language changed to ${selectedLanguage === "gr" ? "English" : "Greek"}`
    );
  };
  return (
    <>
      <header className="header_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-10 col-10">
              <div className="header_left">
                <a href="index.html">
                  <img src={require("../assets/img/logo.png")} alt />
                </a>
                <div className="languagee">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedLanguage}
                    onChange={handleChangeLanguage}
                  >
                    <option value="en">{t("EN")}</option>
                    <option value="gr">{t("GR")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-lg-block d-md-none d-none">
              <div className="header_menus">
                <ul>
                  <li>
                    <Link
                      className="active"
                      to="#"
                      id="banner_part"
                      //   onClick={scrollToSectionbannerpart}
                    >
                      {/* {t("Home")} */}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="index.html#howitworkss"
                      //  onClick={scrollToSection}
                    >
                      {/* {t("How It Works")} */}
                    </a>
                  </li>
                  <li>
                    <a
                      href="index.html#Settings"
                      //   onClick={scrollToSectionsetting}
                    >
                      {/* {t("About Product")} */}
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      // title="info@athlopolis.com"
                      href="javascript:void(0)"
                    >
                      {/* {t("Contact")} */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-2 col-2 text-lg-start text-md-end text-end">
              <div className="social_box">
                <Link
                  className="social_ico"
                  to="https://www.instagram.com/athlopolis.sport/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/insta.svg" alt="instagram" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.facebook.com/profile.php?id=61555006059082"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/facebook.svg" alt="facebook" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/linkden.svg"
                    alt="linkedin"
                    style={{ height: "41px" }}
                  />
                </Link>
                <Link
                  className="social_ico"
                  to={`https://x.com/home?lang=${selectedLanguage}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/X.svg"
                    alt="tweet"
                    style={{ height: "41px" }}
                  />
                </Link>
                <div className="dropdown header_dropdown d-none">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/img/dots.svg" alt />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobile_menuss">
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="assets/img/menu-4-line.svg" alt />
                </a>
                <div
                  className="offcanvas offcanvas-start visible"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                      <img src="assets/img/logo.png" alt />
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      id="buttonclose"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="header_menus d-none">
                      <ul>
                        <li>
                          <Link
                            className="active"
                            to="#"
                            id="banner_part"
                            // onClick={scrollToSectionbannerpart}
                          >
                            {/* {t("Home")} */}
                          </Link>
                        </li>
                        <li>
                          <a
                            href="index.html#howitworkss"
                            // onClick={scrollToSection}
                          >
                            {/* {t("How It Works")} */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#Settings"
                            // onClick={scrollToSectionsetting}
                          >
                            {/* {t("About Product")} */}
                          </a>
                        </li>
                        <li>
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            // title="info@athlopolis.com"
                            href="javascript:void(0)"
                          >
                            {t("Contact")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="social_box">
                      <Link
                        className="social_ico"
                        to="https://www.instagram.com/athlopolis.sport/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/insta.svg" alt="instagram" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.facebook.com/profile.php?id=61555006059082"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/facebook.svg" alt="facebook" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/linkden.svg" alt="linkedin" />
                      </Link>
                      <Link
                        className="social_ico"
                        to={`https://x.com/home?lang=${selectedLanguage}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/X.png" alt="tweet" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="contact_page py-5">
        <div className="container-fluid py-lg-3">
          <div className="row contact_inner">
            <div className="col-lg-6 pe-lg5">
              <div className="contact_content">
                <h1>{t("Get In Touch With Us!")}</h1>
                <p>{t("Let’s script an amazing success story!")}</p>
                <img
                  src={require("../assets/img/contact_img.png")}
                  alt="contact"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <form
                className="row form_design"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control text-white"
                    placeholder={t("First Name*")}
                    {...register("firstName", {
                      required: t("First name is required*"),
                      pattern: {
                        value: /^(?! )[A-Za-z ]+(?<! )$/,
                        message: t(
                          "First name must contain only letters, and should not start or end with white space"
                        ),
                      },
                    })}
                  />
                  {errors.firstName && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.firstName?.message}
                    </small>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control text-white"
                    placeholder={t("Last Name*")}
                    {...register("lastName", {
                      required: t("Last name is required*"),
                      pattern: {
                        value: /^(?! )[A-Za-z ]+(?<! )$/,
                        message: t(
                          "Last name must contain only letters, and should not start or end with white space"
                        ),
                      },
                    })}
                  />
                  {errors.lastName && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.lastName?.message}
                    </small>
                  )}
                </div>
                <div className="form-group col-md-6">
                  <PhoneInput
                    country={"gr"}
                    // value={countryCode + phone}
                    containerClass={classNames("", {
                      "is-invalid": !phone && errors?.contactNumber,
                    })}
                    {...register("contactNumber", {
                      required: t("Contact number is required*"),
                      // pattern: {
                      //   value: /^[0-9]+$/,
                      //   message: "Invalid contact number format",
                      // },
                    })}
                    onChange={handlePhoneChange}
                  />
                  {errors.contactNumber && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.contactNumber?.message}
                    </small>
                  )}
                </div>

                {/* <div className="form-group col-md-6 d-none">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Contact Number*")}
                    {...register("contactNumber", {
                      required: t("Contact Number is required*"),
                      pattern: /^[0-9]+$/,
                    })}
                  />
                  {errors.contactNumber && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.contactNumber?.message}
                    </small>
                  )}
                </div> */}
                <div className="form-group col-md-6">
                  <select
                    className="form-control"
                    {...register("gender", {
                      required: t("Gender is required*"),
                    })}
                  >
                    <option value="" disabled selected>
                      {t("Select Gender*")}
                    </option>
                    <option value="Male">{t("Male")}</option>
                    <option value="Female">{t("Female")}</option>
                    <option value="Other">{t("Other")}</option>
                  </select>
                  {errors.gender && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.gender?.message}
                    </small>
                  )}
                </div>

                {/* <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control text-white"
                    placeholder={t("Gender*")}
                    {...register("gender", {
                      required: t("Gender is required*"),
                    })}
                  />
                  {errors.gender && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.gender?.message}
                    </small>
                  )}
                </div> */}
                <div className="form-group col-md-12">
                  <input
                    type="text"
                    className="form-control text-white"
                    placeholder={t("Email Address*")}
                    {...register("email", {
                      required: t("Email is required*"),
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.email?.message}
                    </small>
                  )}
                </div>
                <div className="form-group col-md-12">
                  <textarea
                    className="form-control text-white"
                    placeholder={t("Description")}
                    {...register("description", {
                      // required: t("Description is required*"),
                    })}
                  />
                  {errors.description && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.description?.message}
                    </small>
                  )}
                </div>
                <div className="form-group col-md-12 custom_check mt-md-3 mt-2">
                  <input
                    type="checkbox"
                    className="d-none"
                    id="check"
                    {...register("check", {
                      required: t(
                        "You must agree to the privacy policy and terms of service*"
                      ),
                    })}
                  />
                  <label htmlFor="check">
                    {t("I agree to Privacy Policy")} &amp;{" "}
                    {t("Terms of Service.")}
                  </label>
                  {errors.check && (
                    <small className="errorText mx-0 fw-bold">
                      {errors.check?.message}
                    </small>
                  )}
                </div>
                <div className="form-group col-md-12 mt-md-3 mt-2">
                  <button
                    type={loading ? "button" : "submit"}
                    disabled={loading}
                    style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    className="submit_btn"
                  >
                    {loading ? (
                      <>
                        {t("Wait")}{" "}
                        <Spinner
                          style={{
                            fontSize: "1.5rem",
                            marginLeft: "5px",
                            height: "1em",
                            width: "1em",
                          }}
                        />
                      </>
                    ) : (
                      t("Submit")
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer selectedLanguage={selectedLanguage} />
    </>
  );
}

export default ContactUs;
