import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

function AppScreenSlider() {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    // autoplay: true,
    // autoplaySpeed: 3000,
    centerMode: true,
    afterChange: (current) => setCurrentSlide(current),
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1440, // Extra large devices (desktops, large screens)
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024, // Tablets (including iPads in landscape mode)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets in portrait mode, small tablets
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600, // Large mobile devices (phablets)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile devices (portrait mode)
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const settings = {
  //   // dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 5,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   // autoplay: true,
  //   // autoplaySpeed: 3000,
  //   centerMode: true,
  //   afterChange: (current) => setCurrentSlide(current),
  //   centerPadding: "10px",
  //   responsive: [
  //     {
  //       breakpoint: 768, // Define the breakpoint for mobile devices
  //       settings: {
  //         slidesToShow: 1, // Show only one slide on mobile
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const images = [
    "assets/img/findfirstmatches.png",
    "assets/img/overviewnew.png",
    "assets/img/interections.png",
    "assets/img/wilson.png",
    "assets/img/joincreates.png",
    "assets/img/howitwork.svg",
  ];

  return (
    <div className="container-fluid text-center">
      <div className="settingss">{t("Venues")}</div>
      <div className="app_screen_slider">
        <Slider ref={sliderRef} {...settings}>
          {images.map((src, index) => (
            <div
              className={`appscreen_img slick-slide ${
                index === currentSlide
                  ? "slick-current slick-active slick-center"
                  : "slick-cloned"
              }`}
              key={index}
            >
              <img src={src} alt={`App screen ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default AppScreenSlider;
