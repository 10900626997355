// localSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const localSlice = createSlice({
  name: "local",
  initialState: {
    token: localStorage.getItem("patentatokenwebsite") || null,
    userid: localStorage.getItem("patentauserloginId") || null,
    fcmtoken: localStorage.getItem("patentaAdminFcmtoken") || null,
    optionAddIdea: localStorage.getItem("selectoptionforbusinessidea") || null,
    todaymsg: localStorage.getItem("todaymsg") || null,
    ids: localStorage.getItem("ideaIds") || null,
    detailsPageIds: localStorage.getItem("detailsPageIds") || null,
    updateStatus: localStorage.getItem("selectUpdateStatus") || null,
    defaultRating: localStorage.getItem("defaultRating") || null,
    paymentData: localStorage.getItem("paymentData") || null,
    patentauserFullName: localStorage.getItem("patentauserFullName") || null,
    userEmail: localStorage.getItem("userEmail") || null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("patentatokenwebsite", action.payload);
    },
    setUserId: (state, action) => {
      state.userid = action.payload;
      localStorage.setItem("patentauserloginId", action.payload);
    },
    setFcmToken: (state, action) => {
      state.fcmtoken = action.payload;
      localStorage.setItem("patentaAdminFcmtoken", action.payload);
    },
    setOptionchangeAtAddIdea: (state, action) => {
      state.optionAddIdea = action.payload;
      localStorage.setItem("selectoptionforbusinessidea", action.payload);
    },
    setUpdateStatus: (state, action) => {
      state.updateStatus = action.payload;
      localStorage.setItem("selectUpdateStatus", action.payload);
    },
    setTodaymsg: (state, action) => {
      state.todaymsg = action.payload;
      localStorage.setItem("todaymsg", action.payload);
    },
    setIds: (state, action) => {
      state.ids = action.payload;
      localStorage.setItem("ideaIds", action.payload);
    },
    setDetailsPageIds: (state, action) => {
      state.detailsPageIds = action.payload;
      localStorage.setItem("detailsPageIds", action.payload);
    },
    setDefaultRating: (state, action) => {
      state.defaultRating = action.payload;
      localStorage.setItem("defaultRating", action.payload);
    },
    setPatentauserFullName: (state, action) => {
      state.patentauserFullName = action.payload;
      localStorage.setItem("patentauserFullName", action.payload);
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
      localStorage.setItem("userEmail", action.payload);
    },
    // setPaymentData: (state, action) => {
    //   state.paymentData = action.payload;
    //   localStorage.setItem("paymentData", action.payload);
    //   console.log("action.payload", action.payload);
    // },
    setPaymentData: (state, action) => {
      state.paymentData = action.payload;
      localStorage.setItem("paymentData", JSON.stringify(action.payload)); // Convert object to string before storing in localStorage
      console.log("action.payload", action.payload);
    },
  },
});

export const {
  setToken,
  setFcmToken,
  setUserId,
  setOptionchangeAtAddIdea,
  setTodaymsg,
  setIds,
  setUpdateStatus,
  setDefaultRating,
  setPaymentData,
  setDetailsPageIds,
  setPatentauserFullName,
  setUserEmail,
} = localSlice.actions;
export default localSlice.reducer;
