import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PostApi } from "../services/Post";

import { localSlice } from "./slices/localSlice";

export const store = configureStore({
  reducer: {
    [PostApi.reducerPath]: PostApi.reducer,
    local: localSlice.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(PostApi.middleware),
});

setupListeners(store.dispatch);
