// import React, { useState, useEffect } from "react";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const NetworkStatus = () => {
//   const [isOnline, setIsOnline] = useState(navigator.onLine);
//   const [previousStatus, setPreviousStatus] = useState(navigator.onLine);

//   useEffect(() => {
//     const handleOnline = () => {
//       setIsOnline(true);
//     };

//     const handleOffline = () => {
//       setIsOnline(false);
//     };

//     window.addEventListener("online", handleOnline);
//     window.addEventListener("offline", handleOffline);

//     return () => {
//       window.removeEventListener("online", handleOnline);
//       window.removeEventListener("offline", handleOffline);
//     };
//   }, []);

//   useEffect(() => {
//     if (isOnline !== previousStatus) {
//       if (isOnline) {
//         toast.success("You are back online! All features are now available.");
//       } else {
//         toast.error(
//           "No internet connection. Some features may be unavailable."
//         );
//       }
//       setPreviousStatus(isOnline);
//     }
//   }, [isOnline, previousStatus]);

//   return (
//     <>
//       <ToastContainer />
//     </>
//   );
// };

// export default NetworkStatus;

import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [previousStatus, setPreviousStatus] = useState(navigator.onLine);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    setIsMounted(true);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isMounted && isOnline !== previousStatus) {
      if (isOnline) {
        toast.success("You are back online! All features are now available.");
      } else {
        toast.error(
          "No internet connection. Some features may be unavailable."
        );
      }
      setPreviousStatus(isOnline);
    }
  }, [isOnline, previousStatus, isMounted]);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default NetworkStatus;
