import React, { useEffect, useState } from "react";
import Footer from "../commoncomponents/Footer";
import { useGetContentMutation } from "../services/Post";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Tooltip } from "bootstrap";

function TermsPrivacy() {
  const [content] = useGetContentMutation();
  const [loader, setLoader] = useState(false);
  const [about, setAbout] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("en");

  console.log("selectedLanguage", selectedLanguage);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "en";
    setSelectedLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    toast.success(
      `Language changed to ${selectedLanguage === "gr" ? "English" : "Greek"}`
    );
  };
  useEffect(() => {
    contentDetail();
  }, []);

  const contentDetail = async () => {
    setLoader(true);
    const { data } = await content({
      type: "T&C",
    });
    if (!data.error) {
      setAbout(data?.results?.content);
      setLoader(false);
    }
  };

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <>
      <header className="header_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-10 col-10">
              <div className="header_left">
                <Link to="/">
                  <img src={require("../assets/img/logo.png")} alt="" />
                </Link>
                <div className="languagee">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedLanguage}
                    onChange={handleChangeLanguage}
                  >
                    <option value="en">{t("EN")}</option>
                    <option value="gr">{t("GR")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-lg-block d-md-none d-none">
              <div className="header_menus d-none">
                <ul>
                  <li>
                    <Link
                      className="active"
                      to="#"
                      id="banner_part"
                      //   onClick={scrollToSectionbannerpart}
                    >
                      {/* {t("Home")} */}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="index.html#howitworkss"
                      //  onClick={scrollToSection}
                    >
                      {/* {t("How It Works")} */}
                    </a>
                  </li>
                  <li>
                    <a
                      href="index.html#Settings"
                      //   onClick={scrollToSectionsetting}
                    >
                      {/* {t("About Product")} */}
                    </a>
                  </li>
                  <li>
                    <a
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="info@athlopolis.com"
                      href="javascript:void(0)"
                    >
                      {t("Contact")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-2 col-2 text-lg-start text-md-end text-end">
              <div className="social_box">
                <Link
                  className="social_ico"
                  to="https://www.instagram.com/athlopolis.sport/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/insta.svg" alt="instagram" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.facebook.com/profile.php?id=61555006059082"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/facebook.svg" alt="facebook" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/linkden.svg"
                    alt="linkedin"
                    style={{ height: "41px" }}
                  />
                </Link>
                <Link
                  className="social_ico"
                  to={`https://x.com/home?lang=${selectedLanguage}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/X.svg"
                    alt="tweet"
                    style={{ height: "41px" }}
                  />
                </Link>
                <div className="dropdown header_dropdown d-none">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/img/dots.svg" alt />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobile_menuss">
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="assets/img/menu-4-line.svg" alt />
                </a>
                <div
                  className="offcanvas offcanvas-start visible"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                      <img src="assets/img/logo.png" alt />
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      id="buttonclose"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="header_menus d-none">
                      <ul>
                        <li>
                          <Link
                            className="active"
                            to="#"
                            id="banner_part"
                            // onClick={scrollToSectionbannerpart}
                          >
                            {/* {t("Home")} */}
                          </Link>
                        </li>
                        <li>
                          <a
                            href="index.html#howitworkss"
                            // onClick={scrollToSection}
                          >
                            {/* {t("How It Works")} */}
                          </a>
                        </li>
                        <li>
                          <a
                            href="index.html#Settings"
                            // onClick={scrollToSectionsetting}
                          >
                            {/* {t("About Product")} */}
                          </a>
                        </li>
                        <li>
                          <a
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="info@athlopolis.com"
                            href="javascript:void(0)"
                          >
                            {t("Contact")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="social_box">
                      <Link
                        className="social_ico"
                        to="https://www.instagram.com/athlopolis.sport/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/insta.svg" alt="instagram" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.facebook.com/profile.php?id=61555006059082"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/facebook.svg" alt="facebook" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/linkden.svg" alt="linkedin" />
                      </Link>
                      <Link
                        className="social_ico"
                        to={`https://x.com/home?lang=${selectedLanguage}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/X.png" alt="tweet" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="commanservices">
        <div className="container p-5 bg-white">
          <div className="row">
            <div className="col-md-12">
              <div class="commanservices_box2">
                <h3>
                  {i18n.language === "gr" ? about?.title_gr : about?.title_en}
                </h3>
                {/* <p>{about?.content_en}</p> */}
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    i18n.language === "gr"
                      ? about?.content_gr
                      : about?.content_en
                  )}
                />
              </div>{" "}
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </>
  );
}

export default TermsPrivacy;
