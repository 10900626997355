import React, { useEffect, useRef, useState } from "react";
import Footer from "../commoncomponents/Footer";
import { useSubscribeMutation } from "../services/Post";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import classNames from "classnames";
import Header from "../commoncomponents/Header";
import { useTranslation } from "react-i18next";
import { Tooltip } from "bootstrap";
import { Link } from "react-router-dom";

function Partner() {
  const [activeTab, setActiveTab] = useState("profile");

  const [subscribe, { isLoading: loading }] = useSubscribeMutation();

  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "en";
    setSelectedLanguage(storedLanguage);
    i18n.changeLanguage(storedLanguage);
  }, [i18n]);

  const handleChangeLanguage = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);
    toast.success(
      `Language changed to ${selectedLanguage === "gr" ? "English" : "Greek"}`
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm();

  const handleSubscribe = async (info) => {
    try {
      const res = await subscribe({
        email: info?.email,
        language: selectedLanguage === "gr" ? "Greek" : "English",
      });

      if (!res?.data?.error) {
        toast.success(
          res.data.message || t("You have successfully subscribed!")
        );
        reset();
      } else {
        toast.error(
          res.data.message || t("Subscription failed. Please try again.")
        );
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again later."));
    }
  };

  const scrollToSectionsetting = (event) => {
    event.preventDefault();
    document.getElementById("buttoncloses").click();
    document
      .getElementById("howitworkss1")
      .scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionbannerpart = (event) => {
    event.preventDefault();
    document.getElementById("buttoncloses").click();
    document
      .getElementById("banner_part")
      .scrollIntoView({ behavior: "smooth" });
  };
  const scrollToSectionallview = (event) => {
    event.preventDefault();
    document.getElementById("buttoncloses").click();
    document.getElementById("allview").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const handleDownloadClick = () => {
    // Handle download button click here
    console.log("Download button clicked");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div>
      <header className="header_main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 col-md-10 col-10">
              <div className="header_left">
                <Link to="/">
                  <img src={require("../assets/img/logo.png")} alt="" />
                </Link>
                <div className="languagee">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectedLanguage}
                    onChange={handleChangeLanguage}
                  >
                    <option value="en">{t("EN")}</option>
                    <option value="gr">{t("GR")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-lg-block d-md-none d-none">
              <div className="header_menus">
                <ul>
                  <li>
                    <Link
                      className="active"
                      to="#"
                      id="banner_part"
                      onClick={scrollToSectionbannerpart}
                    >
                      {t("Home")}
                    </Link>
                  </li>
                  <li>
                    <a href="#howitworkss1" onClick={scrollToSectionsetting}>
                      {t("How It Works")}
                    </a>
                  </li>
                  <li>
                    <a href="#allview" onClick={scrollToSectionallview}>
                      {t("About Product")}
                    </a>
                  </li>
                  <li>
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      // title="info@athlopolis.com"
                      to="/contact-us"
                    >
                      {t("Contact")}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-2 col-2 text-lg-start text-md-end text-end"
              style={{ zIndex: "999" }}
            >
              <div className="social_box">
                <Link
                  className="social_ico"
                  to="https://www.instagram.com/athlopolis_app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/insta.svg" alt="instagram" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.facebook.com/profile.php?id=61555006059082"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="assets/img/facebook.svg" alt="facebook" />
                </Link>
                <Link
                  className="social_ico"
                  to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/linkden.svg"
                    alt="linkedin"
                    style={{ height: "41px" }}
                  />
                </Link>
                <Link
                  className="social_ico"
                  to={`https://x.com/home?lang=${selectedLanguage}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="assets/img/X.svg"
                    alt="tweet"
                    style={{ height: "41px" }}
                  />
                </Link>
                <div className="dropdown header_dropdown d-none">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src="assets/img/dots.svg" alt />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobile_menuss">
                <a
                  className="btn btn-primary"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <img src="assets/img/menu-4-line.svg" alt />
                </a>
                <div
                  className="offcanvas offcanvas-start visible"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                      <img src="assets/img/logo.png" alt />
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-reset"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      id="buttoncloses"
                    />
                  </div>
                  <div className="offcanvas-body">
                    <div className="header_menus">
                      <ul>
                        <li>
                          <Link
                            className="active"
                            to="#"
                            id="banner_part"
                            onClick={scrollToSectionbannerpart}
                          >
                            {t("Home")}
                          </Link>
                        </li>
                        <li>
                          <a
                            href="#howitworkss1"
                            onClick={scrollToSectionsetting}
                          >
                            {t("How It Works")}
                          </a>
                        </li>
                        <li>
                          <a href="#allview" onClick={scrollToSectionallview}>
                            {t("About Product")}
                          </a>
                        </li>
                        <li>
                          <Link
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            // title="info@athlopolis.com"
                            to="/contact-us"
                            Tooltip="info@athlopolis.com"
                          >
                            {t("Contact")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="social_box">
                      <Link
                        className="social_ico"
                        to="https://www.instagram.com/athlopolis.sport/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/insta.svg" alt="instagram" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.facebook.com/profile.php?id=61555006059082"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/facebook.svg" alt="facebook" />
                      </Link>
                      <Link
                        className="social_ico"
                        to="https://www.linkedin.com/feed/?trk=guest_homepage-basic_google-one-tap-submit"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/linkden.svg" alt="linkedin" />
                      </Link>
                      <Link
                        className="social_ico"
                        to={`https://x.com/home?lang=${selectedLanguage}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src="assets/img/X.png" alt="tweet" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <Header /> */}
      <section className="Partner_banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="Partnerbanner_content">
                <div className="Early_access">
                  <img src="assets/img/Frame.svg" alt="frame" />
                </div>
                <h1>
                  {t("ATHLOPOLIS")} <span>{t("PARTNER")}</span>
                </h1>
                <p>
                  {t(
                    "Get your hands on the most powerful venue management tool. From tracking sales, to messaging customers, and to even managing your staff team!"
                  )}
                </p>
                <form
                  className="emailbox"
                  action=""
                  onSubmit={handleSubmit(handleSubscribe)}
                >
                  <div className="form-group position-relative">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className={classNames(
                        "form-control border border-secondary ",
                        {
                          "is-invalid": errors?.email,
                        }
                      )}
                      placeholder={t("Enter your email")}
                      {...register("email", {
                        required: t("Email is Required*"),
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: t("Invalid email address"),
                        },
                      })}
                    />
                    <button
                      className="getstarted border-0"
                      type={loading ? "button" : "submit"}
                      disabled={loading}
                      style={{ cursor: loading ? "not-allowed" : "pointer" }}
                    >
                      {loading ? (
                        <>
                          {t("Wait")}{" "}
                          <Spinner
                            style={{
                              fontSize: "2rem",
                              verticalAlign: "middle",
                            }}
                            className="ms-2"
                          />
                        </>
                      ) : (
                        t("Let’s Talk!")
                      )}
                    </button>
                  </div>
                  {errors?.email && (
                    <small className="errorText mx-1 fw-bold text-danger mt-1">
                      {errors?.email?.message}
                    </small>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <img className="cut_img_1" src="assets/img/cut1.svg" alt="" />
        <img className="cut_img_2" src="assets/img/cut2.svg" alt="" />
      </section>
      <div id="howitworkss1" className="container-small">
        <div className="row partnermain">
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes full_box">
              <div className="partner_uperheding">{t("Overview")}</div>
              <img className="overview_img" src="assets/img/overview.png" alt />
            </div>
          </div>
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes small_boxess">
              <div className="text-center">
                <img
                  className="small_icons"
                  src="assets/img/customer_messagge.png"
                  alt
                />
                <div className="small_head">{t("Customer Messages")}</div>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes small_boxess">
              <div className="text-center">
                <img
                  className="small_icons"
                  src="assets/img/notifications_unread.png"
                  alt
                />
                <div className="small_head">
                  {t("Instant Web Notifications")}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes full_box">
              <div className="partner_uperheding">{t("Business Reports")}</div>
              <img className="bussiness_report" src="assets/img/card.png" alt />
            </div>
          </div>
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes full_box">
              <div className="partner_uperheding">
                {t("Reviews")} &amp; {t("Rating")}
              </div>
              <img
                className="reviewimg"
                src="assets/img/reviewrating.png"
                alt
              />
            </div>
          </div>
          <div className="col-md-6 px-2 mb-3 partneradjust">
            <div className="partner_boxes full_box">
              <div className="partner_uperheding">
                {t("Organized Calendar View")}
              </div>
              <img
                className="calender_img"
                src="assets/img/calender_view.png"
                alt
              />
            </div>
          </div>
          <div className="col-md-3 px-2 mb-3">
            <div className="partner_boxes small_boxess">
              <div className="text-center">
                <img
                  className="small_icons"
                  src="assets/img/staff_icon.png"
                  alt
                />
                <div className="small_head">{t("Staff Management")}</div>
              </div>
            </div>
          </div>
          <div className="col-md-3 px-2 invisible"></div>
          <div className="col-md-3 px-2 mb-3 partneradjust">
            <div className="partner_boxes small_boxess">
              <div className="text-center">
                <img
                  className="small_icons"
                  src="assets/img/bookings.png"
                  alt
                />
                <div className="small_head">{t("Make/Remove Bookings")}</div>
              </div>
            </div>
          </div>
          <div className="col-md-6 px-2 mb-3 partneradjust">
            <div className="partner_boxes track_box">
              <div className="partner_uperheding">
                {t("Track Finances")} &amp; {t("Sales")}
              </div>
              <img className="calender_img" src="assets/img/track.png" alt />
            </div>
          </div>
        </div>
      </div>
      <section className="commanservices">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="comman_head text-center">
                <h2>{t("Built for you")}</h2>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/manage.svg" alt="manage" />
                <h3>{t("Manage")}</h3>
                <p>
                  {t(
                    "Easily manage your venue and staff. Keep track of schedules, customers, and financial transactions all in one place."
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/organize.svg" alt />
                <h3>{t("Organise")}</h3>
                <p>
                  {/* {t("Schedule")} &amp;{" "} */}
                  {t(
                    "Optimise your venue by managing venue courts and streamline bookings and court availability."
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="commanservices_box">
                <img src="assets/img/review.svg" alt />
                <h3>{t("Review")}</h3>
                <p>
                  {/* {t("Meet")} &amp;{" "} */}
                  {t(
                    "Enhance your reputation by monitoring customer feedback and ratings, manage your venue profile and respond to reviews."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="allview" className="allview">
        <div className="container-small">
          <div className="row">
            <div className="col-12 text-center">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "home" ? "active" : ""
                    }`}
                    id="home-tab"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected={activeTab === "home"}
                    onClick={() => handleTabChange("home")}
                  >
                    <img src="assets/img/phone.svg" alt="" />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "profile" ? "active" : ""
                    }`}
                    id="profile-tab"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected={activeTab === "profile"}
                    onClick={() => handleTabChange("profile")}
                  >
                    <img src="assets/img/desxtop.svg" alt="Desktop" />
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "contact" ? "active" : ""
                    }`}
                    id="contact-tab"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected={activeTab === "contact"}
                    onClick={() => handleTabChange("contact")}
                  >
                    <img src="assets/img/tablet.svg" alt="" />
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "home" ? "show active" : ""
                  }`}
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <img
                        className="img-fluid"
                        // src={require("../assets/img/lookingfor.svg")}
                        src="assets/img/lookingfor.svg"
                        alt="Mobile View"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "profile" ? "show active" : ""
                  }`}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <img
                        className="img-fluid"
                        src="assets/img/admin2.svg"
                        alt="Admin View"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "contact" ? "show active" : ""
                  }`}
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div className="row">
                    <div className="col-12">
                      <img
                        className="img-fluid"
                        src="assets/img/admintab.svg"
                        alt="iPad View"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="app_screen">
        <div className="container-small">
          <div className="commaper_with mt-0">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th />
                    <th>{t("Athlopolis Partner")}</th>
                    <th>{t("Competitors")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("Customer Messages / Chats")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Overview of Calendar / Booking Schedule")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Manage Individual Bookings")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("View and Manage Customers")}
                      <p>
                        {t(
                          "Find open matches in your area or match with other players with similar ages and levels."
                        )}
                      </p>
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("Manage")} &amp; {t("Update Court Information")}
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t("Financial Reports")}
                      <p>
                        {t("Receive Weekly / Monthly reports on your Sales")}{" "}
                        &amp; {t("Financial Status.")}
                      </p>
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>{t("Customizable Venue Profile Page")}</td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/check_circle.png"
                        alt
                      />
                    </td>
                    <td>
                      <img
                        className="check_img"
                        src="assets/img/cancel.png"
                        alt
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="downloadapotholis get_app">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="apotholis_content ps-3 text-end">
                <h2>
                  {t("GET THE")} <br />
                  <span>{t("PARTNER")}</span> {t("APP")}
                </h2>
                <p>
                  {t("Get Athlopolis Partner and start managing")} <br />{" "}
                  {t("your sales, customers and staff!")}
                </p>
                <div className="d-md-flex justify-content-end">
                  <a
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("Coming Soon")}
                    className="download_atho"
                    href="javascript:;"
                  >
                    {t("Download for Mobile")}
                  </a>

                  <a
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("Coming Soon")}
                    className="download_athoborder ms-4"
                    href="javascript:;"
                  >
                    {t("Open in Browser")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="downloadapotholis_img">
                <img src={require("../assets/img/getapp.png")} alt />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer selectedLanguage={selectedLanguage} />
    </div>
  );
}

export default Partner;
