import React, { useEffect, useRef } from "react";
import { Tooltip } from "bootstrap";
import { Link, useNavigate } from "react-router-dom";

function Header({ scrollToAllView }) {
  const history = useNavigate();
  const scrollRef = useRef(null);

  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  }, []);

  const handleAboutProductClick = () => {
    history("/partner");
    if (scrollToAllView) {
      scrollToAllView();
    }
  };
  return (
    <header className="header_main">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-3 col-md-10 col-10">
            <div className="header_left">
              <Link to="/">
                <img src={require("../assets/img/logo.png")} alt="" />
              </Link>
              <div className="languagee">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>EN</option>
                  <option value={1}>GR</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-lg-block d-md-none d-none">
            <div className="header_menus">
              <ul>
                <li>
                  <a className="active" href="index.html#banner_part">
                    Home
                  </a>
                </li>
                <li>
                  <a href="index.html#howitworkss">How It Works</a>
                </li>
                <li>
                  <a href="#" onClick={handleAboutProductClick}>
                    About Product
                  </a>
                </li>
                <li>
                  <a
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="info@athlopolis.com"
                    href="javascript:void(0)"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-2 text-lg-start text-md-end text-end">
            <div className="social_box">
              <a className="social_ico" href="javascript:void(0)">
                <img src="assets/img/insta.svg" alt />
              </a>
              <a className="social_ico" href="javascript:void(0)">
                <img src="assets/img/facebook.svg" alt />
              </a>
              <a className="social_ico" href="javascript:void(0)">
                <img src="assets/img/linkden.svg" alt />
              </a>
              <div className="dropdown header_dropdown">
                <button
                  className="btn btn-secondary"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="assets/img/dots.svg" alt />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile_menuss">
              <a
                className="btn btn-primary"
                data-bs-toggle="offcanvas"
                href="#offcanvasExample"
                role="button"
                aria-controls="offcanvasExample"
              >
                <img src="assets/img/menu-4-line.svg" alt />
              </a>
              <div
                className="offcanvas offcanvas-start"
                tabIndex={-1}
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                    <img src="assets/img/logo.png" alt />
                  </h5>
                  <button
                    type="button"
                    className="btn-close text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  />
                </div>
                <div className="offcanvas-body">
                  <div className="header_menus">
                    <ul>
                      <li>
                        <a className="active" href="index.html#banner_part">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="index.html#howitworkss">How It Works</a>
                      </li>
                      <li>
                        <a href="index.html#Settings">About Product</a>
                      </li>
                      <li>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="info@athlopolis.com"
                          href="javascript:void(0)"
                        >
                          Contact
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="social_box">
                    <a className="social_ico" href="javascript:void(0)">
                      <img src="assets/img/insta.svg" alt />
                    </a>
                    <a className="social_ico" href="javascript:void(0)">
                      <img src="assets/img/facebook.svg" alt />
                    </a>
                    <a className="social_ico" href="javascript:void(0)">
                      <img src="assets/img/linkden.svg" alt />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
