import Index from "./home/Index";
import Partner from "./partner/Partner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NetworkStatus from "./network/NetworkStatus";
import TermsPrivacy from "./privacy/TermsPrivacy";
import ContactUs from "./contact/ContactUs";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import About from "./privacy/About";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <NetworkStatus />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/terms-condition" element={<TermsPrivacy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
